/* stylelint-disable no-duplicate-selectors */
.contact-nav {
  background-color: var(--color-blue-800);
  background-image: linear-gradient(to left, var(--color-brand-900), var(--color-blue-800));
}
.contact-nav__list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  padding-block: 0.75em;
}
.contact-nav__item {
  width: 100%;
  border-right: 1px solid #fff;
  padding: 0 1rem;
}
.contact-nav__item:last-child {
  border-right: 0;
}
.contact-nav__link {
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  text-align: center;
  width: 100%;
  min-height: 44px;
  padding: 0.5em;
}
.contact-nav__link i {
  font-size: 1.75rem;
}
@media (max-width: 767px) {
  .contact-nav__link {
    flex-direction: column;
    font-size: 0.875em;
  }
}
.contact-nav {
  margin-bottom: 0;
}
@media (max-width: 1023px) {
  .contact-nav {
    margin-bottom: 3.5rem;
  }
}
.contact-nav > .container {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-block-start: 0;
}

.theme-dark .contact-nav {
  background-color: var(--color-blue-1400);
  background-image: unset;
}

.sticky-toggle-button {
  --btn-bg-color: var(--color-brand-900);
  position: fixed;
  bottom: 72px;
  right: 1rem;
  border-radius: 4px;
  background-color: var(--btn-bg-color);
  border: none;
  color: #fff;
  cursor: pointer;
  z-index: 499;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.sticky-toggle-button::after {
  content: "";
  position: absolute;
  max-width: 0;
  max-height: 0;
  border-left: 0.5rem solid transparent;
  border-right: 0 solid transparent;
  border-top: 0.5rem solid var(--btn-bg-color);
  bottom: -0.5rem;
  right: 1rem;
}
.sticky-toggle-button span {
  display: inline-block;
  font-size: 1.563rem;
  font-weight: 500;
  z-index: 50;
}
.sticky-toggle-button:hover {
  --btn-bg-color: var(--color-blue-800);
  transform: translateY(-2px);
  box-shadow: 0 7px 14px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}
.sticky-toggle-button[aria-expanded=true] {
  box-shadow: none;
}

@media (max-width: 1023px) {
  .sticky-toggle-button {
    display: none !important;
  }
}
.sticky-contact {
  background-color: var(--color-blue-800);
  background-image: linear-gradient(to left, var(--color-brand-900), var(--color-blue-800));
}
.sticky-contact__list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  padding-block: 0.75em;
}
.sticky-contact__item {
  width: 100%;
  border-right: 1px solid #fff;
  padding: 0 1rem;
}
.sticky-contact__item:last-child {
  border-right: 0;
}
.sticky-contact__link {
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  text-align: center;
  width: 100%;
  min-height: 44px;
  padding: 0.5em;
}
.sticky-contact__link i {
  font-size: 1.75rem;
}
@media (max-width: 767px) {
  .sticky-contact__link {
    flex-direction: column;
    font-size: 0.875em;
  }
}
.sticky-contact {
  border-radius: 4px;
  position: fixed;
  bottom: 72px;
  left: 1rem;
  right: 1rem;
  width: calc(100% - 2rem);
  z-index: 499;
  transform-origin: right;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  display: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  will-change: transform;
}
.sticky-contact > .container {
  padding-block-start: 0;
  padding-left: 1rem;
  padding-right: 1rem;
}
.sticky-contact.is-collapsed {
  transform: scaleX(0);
}
.sticky-contact:not(.is-collapsed) {
  transform: scaleX(1);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1), 0 5px 10px rgba(0, 0, 0, 0.04);
}

.sticky-close-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 5px;
  z-index: 499;
}
.sticky-close-button i {
  font-size: 1.8rem;
}